let api = {
  getBrand: "/ai/foreign/get/brand", //获取品牌
  getModel: "/ai/foreign/get/vehicle/model/", //获取车型
  getAgent: "/ai/foreign/web/intelligent", //获取智能体
  getStartWords: "/ai/foreign/get/intelligent/start/words", //获取开场白
  sendMessage: "/ai/foreign/send/message", //发送消息
  markProblem: "/ai/foreign/mark/problem", //标记问题
  postRate: "/ai/foreign/evaluate/score", //会话评价打分
};

export default api;
