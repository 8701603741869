export default {
  // common
  common: {
    language: 'English',
    changeProduct: 'In order to provide better service, please select the product information you need to consult',
    car_placeholder: 'car brand',
    changeCarType: 'Please select a product',
    model_placeholder: 'Specific car model',
    changeModelType: 'Please select a model',
    join: 'Consult Now',
    sub_placeholder: 'Please enter your question or requirement...',
    name: 'Xin Xiaobo',
    identify: 'Xinbozhi Steering Engineer',
    carName: 'Dongfeng',
    carType: 'FENCON',
    brand: 'brand',
    model: 'model',
    confirm: 'Confirm',
    reset: 'Reset',
    quest: 'Is your problem solved?',
    Resolved: 'Resolved',
    Unresolved: 'Unresolved',
    evaluate: 'Evaluate',
    evaluateVal: 'Thank you for rating this session',
  }
}