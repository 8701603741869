import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import pinia from './stores'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 引入ui icon
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import i18n from './lang'

import plugins from "./plugins"; // plugins
import api from "./utils/api";
import request from "./utils/request";

import { ImagePreview, Swipe, SwipeItem } from 'vant';
import 'vant/lib/index.css';

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.config.globalProperties.$api = api;
app.config.globalProperties.$request = request;

app.use(plugins)
app.use(router)
app.use(pinia)
app.use(ElementPlus)
app.use(i18n)
app.use(ImagePreview);
app.use(Swipe);
app.use(SwipeItem);

app.mount('#app')
