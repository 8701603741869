import { defineStore } from "pinia";
import i18n from "@/lang";
import { ref } from "vue";

export const useConfigStore = defineStore("config", () => {
  //当前语言
  let language = ref(localStorage.getItem('language') || 'zh');

  //设置语言
  const setLanguage = (lang) => {
    const { locale } = i18n.global;
    language.value = lang;
    locale.value = lang;
  }

  return { language, setLanguage };
});