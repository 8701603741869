import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import("@/views/Login"),
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: "/404",
    component: () => import("@/views/error/404"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  // 判断用户是否选择汽车品牌
  if (to.path === '/home' && !to.query.car) {
    next({ path: '/' })
  } else {
    next()
  }
})

export default router
