<template>
  <div class="home-box">
    <div class="title">
      <div class="title_box">
        <div class="title_info">
          <el-image
            fit="contain"
            class="agent_image"
            :src="agentInfo[0].avatar"
            v-if="agentInfo.length > 0"
          ></el-image>
          <div class="title_text">
            <div class="name">{{ $t("common.name") }}</div>
            <div class="identify">{{ $t("common.identify") }}</div>
          </div>
        </div>
        <div v-if="carList && carList.length > 0" class="checkout-button-box">
          <el-button round class="checkoutProduct" @click="drawerOpen()">
            {{ modelName ? carName + "/" + modelName : carName }}
          </el-button>
          <el-icon class="icon-right"><Switch /></el-icon>
        </div>
      </div>
    </div>

    <div
      class="content"
      id="agent_dialog_content"
      :style="
        (tipsList && tipsList.length > 0) || (conversation_id && !rateValue)
          ? 'padding-bottom:140px'
          : 'padding-bottom:106px'
      "
      v-loading="loading"
    >
      <div v-for="(item, index) in info" :key="index">
        <div class="currentTime" v-if="item.position === 3">
          {{ item.currentTime }}
        </div>
        <!-- 智能体回复 -->
        <div class="agent-answer" v-if="item.position === 1">
          <div class="agent-image-box">
            <el-image
              fit="contain"
              class="agent_image"
              :src="agentInfo[0].avatar"
              v-if="agentInfo.length > 0"
            >
            </el-image>
          </div>

          <div class="agent-answer-text" v-if="item.type === 1">
            <div>{{ item.prologue }}</div>
            <div class="answer_quest" v-if="item.options">
              <div
                v-for="value in item.options"
                :key="value"
                :style="value ? 'padding-bottom: 10px' : ''"
              >
                <el-button
                  text
                  bg
                  size="small"
                  class="answer_quest_button"
                  v-if="value !== ''"
                  @click="submit(value)"
                  >{{ value }}</el-button
                >
                <div v-else class="answer_quest_while"></div>
              </div>
            </div>
          </div>

          <div class="agent-answer-text" v-if="item.type === 2">
            <div v-for="(val, j) in item.images" :key="j">
              <el-button class="link-text" text @click="showImage(val.url)">
                <el-icon :size="15" class="link-icon el-icon--left"></el-icon>
                {{ language === "English" ? val.urlEnglishKey : val.urlKey }}
              </el-button>
            </div>
            <div v-for="(val, j) in item.videos" :key="j">
              <el-button class="link-text" text @click="showVideo(val.url)">
                <el-icon :size="15" class="link-icon el-icon--left"></el-icon>
                {{ language === "English" ? val.urlEnglishKey : val.urlKey }}
              </el-button>
            </div>
          </div>

          <div
            class="agent-answer-text"
            :class="index === info.length - 1 ? 'problem_question' : ''"
            v-if="item.type === 4"
          >
            {{ $t("common.quest") }}
            <div class="problem-button" v-if="index === info.length - 1">
              <el-button
                round
                class="problem-button-item"
                @click="isResolveProblem(true)"
              >
                <el-icon :size="15" class="local-icon el-icon--left"></el-icon>
                {{ $t("common.Resolved") }}
              </el-button>
              <el-button
                round
                class="problem-button-item"
                @click="isResolveProblem(false)"
              >
                <el-icon
                  :size="15"
                  class="local-icon local-icon-reject el-icon--left"
                ></el-icon>
                {{ $t("common.Unresolved") }}
              </el-button>
            </div>
          </div>
        </div>

        <!-- 用户提问 -->
        <div class="user-quest" v-if="item.position === 2">
          <div class="user-quest-text">
            {{ item.question }}
          </div>
        </div>
      </div>
      <div class="answer-loading" v-if="!isThinking && isDisabled">
        <div class="loading">
          <div class="k-loader"></div>
        </div>
      </div>

      <!-- 智能体思考 -->
      <div class="agent-answer" v-if="isThinking && isDisabled">
        <div class="agent-image-box">
          <el-image
            fit="contain"
            class="agent_image"
            :src="agentInfo[0].avatar"
            v-if="agentInfo.length > 0"
          >
          </el-image>
        </div>
        <div class="agent-answer-text">
          <div class="loading-box">
            {{ language === "English" ? "Thinking" : "正在输入" }}
            <div class="loading">
              <div class="k-loader k-circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="footer-tips">
        <el-button
          text
          class="tips-button footer-rate"
          @click="showRate"
          v-if="conversation_id && !rateValue"
        >
          <el-icon :size="24" class="rate-button el-icon--left"></el-icon>
          {{ $t("common.evaluate") }}
        </el-button>
        <el-scrollbar>
          <div class="footer-tips-item">
            <el-button
              v-for="(item, index) in tipsList"
              :key="index"
              text
              class="tips-button"
              v-if="tipsList"
              @click="submit(item)"
            >
              {{ item }}
            </el-button>
          </div>
        </el-scrollbar>
      </div>

      <div class="footer-input">
        <el-input
          v-model.trim="input"
          size="large"
          class="subInput"
          :placeholder="$t('common.sub_placeholder')"
          @keyup.enter="submit(input)"
        />
        <el-button
          icon="Top"
          :type="input ? 'primary' : 'info'"
          size="small"
          class="subBtn"
          circle
          @click="submit(input)"
          v-if="!isDisabled"
        />
        <el-button
          type="primary"
          size="small"
          class="subBtn"
          loading
          circle
          v-else
        />
      </div>
    </div>
  </div>

  <el-image-viewer
    @close="
      () => {
        showViewer = false;
      }
    "
    v-if="showViewer"
    :url-list="urlList"
  />

  <el-dialog
    v-model="dialogVisible"
    destroy-on-close
    :width="deviceType === 1 ? '80%' : '450'"
    title=""
  >
    <video
      :src="videoUrl"
      controls
      :autoplay="true"
      :muted="deviceType === 1 ? true : false"
      class="answer_video"
    ></video>
  </el-dialog>

  <!-- 侧边栏 -->
  <el-drawer
    v-model="drawer"
    direction="ltr"
    size="86%"
    :style="{ maxWidth: '486px' }"
  >
    <template #header>
      <h4 style="color: #202532; margin-bottom: 0" v-if="modelIndex === -1">
        {{
          language === "English"
            ? carList[brandIndex]?.englishName
            : carList[brandIndex]?.name
        }}
      </h4>
      <h4 style="color: #202532; margin-bottom: 0" v-else>
        {{
          language === "English"
            ? carList[brandIndex]?.englishName +
              "/" +
              modelList[modelIndex]?.englishName
            : carList[brandIndex]?.name + "/" + modelList[modelIndex]?.name
        }}
      </h4>
    </template>
    <h4
      style="color: #202532; margin: 0 0 10px"
      v-if="carList && carList.length > 0"
    >
      {{ $t("common.brand") }}
    </h4>
    <div class="brand-list" v-loading="carLoading">
      <div
        v-for="(item, index) in carList"
        :key="item.id"
        class="brand-item-box"
        :style="item ? 'padding-bottom: 12px' : ''"
      >
        <el-button
          v-if="item"
          :type="brandIndex === index ? 'primary' : ''"
          text
          bg
          class="brand-button"
          :class="brandIndex === index ? 'brand-color' : 'brand-defult'"
          @click="selectBrand(index)"
        >
          {{ language === "English" ? item.englishName : item.name }}
        </el-button>
        <div v-else class="brand-none"></div>
      </div>
    </div>
    <h4 class="model-title" v-if="modelList && modelList.length > 0">
      {{ $t("common.model") }}
    </h4>
    <div
      class="brand-list"
      v-loading="modelLoading"
      v-if="modelList && modelList.length > 0"
    >
      <div
        v-for="(item, index) in modelList"
        :key="item.id"
        class="brand-item-box"
        :style="item ? 'padding-bottom: 12px' : ''"
      >
        <el-button
          v-if="item"
          :type="modelIndex === index ? 'primary' : ''"
          text
          bg
          class="brand-button"
          :class="modelIndex === index ? 'brand-color' : 'brand-defult'"
          @click="selectModel(index)"
        >
          {{ language === "English" ? item.englishName : item.name }}
        </el-button>
        <div v-else class="brand-none"></div>
      </div>
    </div>
    <template #footer>
      <div class="drawer-footer">
        <!-- <el-button @click="resetClick" class="foot-btn">{{
          $t("common.reset")
        }}</el-button> -->
        <el-button type="primary" class="foot-btn" @click="confirmClick">{{
          $t("common.confirm")
        }}</el-button>
      </div>
    </template>
  </el-drawer>

  <!-- 底部评价 -->
  <div class="rate-drawer">
    <el-drawer v-model="isRate" title="" direction="btt">
      <div class="rate-container">
        <div class="demonstration">{{ $t("common.evaluateVal") }}</div>
        <el-rate
          v-model="rateValue"
          size="large"
          :disabled="Boolean(rateValue)"
          @change="submitRate"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { nextTick, ref, onMounted, getCurrentInstance, onUnmounted } from "vue";
import { showImagePreview } from "vant";
import { useConfigStore } from "@/stores/config";
import { useRoute } from "vue-router";
const route = useRoute();
const { proxy } = getCurrentInstance();
const configStore = useConfigStore();
const deviceType = ref(1); //用户打开页面设备
const user = ref(""); //用户唯一标识
const conversation_id = ref(null); //会话id
const message_id = ref(null); //消息id
const loading = ref(false); //页面加载
const language = ref("中文"); //语言
const agentInfo = ref([]); //智能体信息
const isThinking = ref(false); //智能体是否正在思考
const isDisabled = ref(false); //用户是否能输入
const car = ref(-1); //车辆品牌
const model = ref(-1); //车辆类型车
const carName = ref(""); //选中车辆品牌
const modelName = ref(""); //选中车辆类型车
// 汽车产品
const carList = ref([]);
// 汽车产品加载
const carLoading = ref(false);
// 汽车车型
const modelList = ref([]);
// 汽车车型加载
const modelLoading = ref(false);
const input = ref("");
const drawer = ref(false);
const brandIndex = ref(-1); //当前选中的品牌
const modelIndex = ref(-1); //当前选中的车型
const info = ref([]); //对话信息
const tipsList = ref([]); //猜你想问
const interval = ref(null);
const currentTime = ref("");
const timer = ref({}); //定时器记录时间
const timeLeft = ref(180); //记录时间长度
const showViewer = ref(false); //图片预览展示
const dialogVisible = ref(false); //视频预览展示
const urlList = ref([]); //图片预览地址
const videoUrl = ref([]); //视频预览地址
const isRate = ref(false); //是否显示评分
const rateValue = ref(0); //评分值

// 获取智能体
const getAgent = async () => {
  const res = await proxy.$request({
    url: proxy.$api.getAgent,
    method: "get",
  });
  if (!res.data) return;
  agentInfo.value = res.data;
};

// 获取开场白
const getStartWords = async () => {
  loading.value = true;
  try {
    const res = await proxy.$request({
      url: proxy.$api.getStartWords,
      method: "get",
      params: {
        language: language.value,
      },
    });
    if (!res.data) loading.value = false;
    let timeout = 0;
    for (const item of res.data) {
      item.position = 1;
      item.type = 1;
      if (item.options) {
        item.options.push("", "", "");
      }
      setTimeout(() => {
        info.value.push(item);
        scrollToBottom();
      }, timeout);
      timeout += 500;
    }
    scrollToBottom();
    loading.value = false;
  } catch (error) {
    // proxy.$modal.msgError(error);
    console.error(error);
    loading.value = false;
  }
};

// 获取品牌
const getBrand = async (isFirst) => {
  carLoading.value = true;
  try {
    const res = await proxy.$request({
      url: proxy.$api.getBrand,
      method: "get",
    });
    if (!res.data) carLoading.value = false;
    res.data.push("");
    carList.value = res.data;
    if (isFirst) {
      const index = carList.value.findIndex(
        (item) => item.id == route.query.car
      );
      if (index !== -1) {
        carName.value =
          language.value === "English"
            ? carList.value[index].englishName
            : carList.value[index].name;
      }

      car.value = index;
      brandIndex.value = index;
    }

    carLoading.value = false;
    getModel(route.query.car, isFirst);
  } catch (error) {
    // proxy.$modal.msgError(error);
    console.error(error);
    carLoading.value = false;
  }
};

// 获取车型
const getModel = async (value, isFirst) => {
  if (!value) return;
  modelList.value = [];
  modelIndex.value = -1;
  modelLoading.value = true;
  try {
    const res = await proxy.$request({
      url: proxy.$api.getModel + value,
      method: "get",
    });
    if (!res.data) modelLoading.value = false;
    res.data.push("");
    modelList.value = res.data;
    if (isFirst) {
      const index = modelList.value.findIndex(
        (item) => item.id == route.query.model
      );

      if (index !== -1) {
        modelName.value =
          language.value === "English"
            ? modelList.value[index].englishName
            : modelList.value[index].name;
      }

      model.value = index;
      modelIndex.value = index;
    }

    modelLoading.value = false;
  } catch (error) {
    // proxy.$modal.msgError(error);
    console.error(error);
    modelLoading.value = false;
  }
};

// 显示评价
const showRate = () => {
  isRate.value = true;
};

// 提交评价
const submitRate = async () => {
  if (rateValue.value === 0) {
    proxy.$modal.msgError(
      language.value === "English" ? "Please select a rating" : "请选择评分"
    );
    return;
  }
  try {
    await proxy.$request({
      url: proxy.$api.postRate,
      method: "post",
      data: {
        conversationId: conversation_id.value,
        score: rateValue.value,
      },
    });
    proxy.$modal.msgSuccess(
      language.value === "English"
        ? "Thank you for rating this session"
        : "感谢您的打分"
    );
    isRate.value = false;
  } catch (error) {
    rateValue.value = 0;
    // proxy.$modal.msgError(error);
    console.error(error);
  }
};

// 获取当前时间
const getCurrentTime = () => {
  const mydate = new Date();
  const hour = mydate.getHours();
  const minute = mydate.getMinutes();
  info.value.push({ position: 3, currentTime: hour + ":" + minute });
};

// 开启定时器
const startTimer = () => {
  timeLeft.value = 180; // 重置计时器
  if (timer.value) {
    clearTimeout(timer.value);
  }
  timer.value = setTimeout(() => {
    timeLeft.value = 0;
  }, 180000);
};

// 展示图片
const showImage = (url) => {
  if (deviceType.value === 2) {
    showViewer.value = true;
    urlList.value = [url];
  } else if (deviceType.value === 1) {
    previewImage(url);
  }
};

// 展示图片
const showVideo = (url) => {
  dialogVisible.value = true;
  videoUrl.value = url;
};

// 跳转到消息底部
const scrollToBottom = () => {
  nextTick(() => {
    document.querySelector("#agent_dialog_content").scrollTop =
      document.querySelector("#agent_dialog_content").scrollHeight;
  });
};

// 用户自己提问
const submit = async (question) => {
  if (isDisabled.value) return;
  if (question === "") return;
  isThinking.value = true;
  isDisabled.value = true;
  if (!timeLeft.value) {
    getCurrentTime();
  }
  startTimer();
  try {
    proxy
      .$request({
        url: proxy.$api.sendMessage,
        method: "post",
        data: {
          qurey: question,
          brand: carList.value[car.value]?.name,
          type: modelList.value[model.value]?.name,
          englishBrand: carList.value[car.value]?.englishName,
          englishType: modelList.value[model.value]?.englishName,
          userid: user.value,
          secret: agentInfo.value[0].secret,
          conversation_id: conversation_id.value,
          peopleId: agentInfo.value[0].id,
          channel: deviceType.value,
        },
      })
      .then((res) => {
        if (!res.data) {
          isThinking.value = false;
          isDisabled.value = false;
        }
        let msgList = res.data.msg.split("\n\n");
        tipsList.value = res.data.recommend;
        let timeout = 0;
        for (let i = 0; i < msgList.length; i++) {
          if (!msgList[i]) continue;
          setTimeout(() => {
            if (msgList[i]) {
              info.value.push({ position: 1, type: 1, prologue: msgList[i] });
              scrollToBottom();
            }
          }, timeout);
          timeout += 2000;
        }

        if (
          (res.data.imageVos && res.data.imageVos.length) ||
          (res.data.videoVos && res.data.videoVos.length)
        ) {
          setTimeout(() => {
            info.value.push({
              position: 1,
              type: 2,
              images: res.data.imageVos || [],
              videos: res.data.videoVos || [],
            });
            scrollToBottom();
          }, timeout);
          timeout += 2000;
        }

        if (res.data.type === "有解决方案") {
          setTimeout(() => {
            info.value.push({ position: 1, type: 4 });
            scrollToBottom();
          }, timeout);
          timeout += 2000;
        }

        scrollToBottom();
        isThinking.value = false;

        conversation_id.value = res.data.conversation_id;
        message_id.value = res.data.message_id;
        if (timeout >= 2000) {
          timeout -= 2000;
        }
        setTimeout(() => {
          isDisabled.value = false;
        }, timeout);
      })
      .catch((error) => {
        isThinking.value = false;
        isDisabled.value = false;
        // proxy.$modal.msgError(error);
        console.error(error);
      });
    input.value = "";
    info.value.push({ position: 2, question });
    scrollToBottom();
  } catch (error) {
    isThinking.value = false;
    isDisabled.value = false;
    // proxy.$modal.msgError(error);
    console.error(error);
  }
};

// 手机图片预览
const previewImage = (url) => {
  showImagePreview({
    images: [url],
    loop: false,
  });
};

// 是否解决问题
const isResolveProblem = async (isSolve) => {
  if (isSolve) {
    submit(language.value === "English" ? "Resolved" : "已解决");
  } else {
    submit(language.value === "English" ? "Unresolved" : "未解决");
  }

  try {
    await proxy.$request({
      url: proxy.$api.markProblem,
      method: "post",
      data: {
        message_id: message_id.value,
        solve: isSolve,
      },
    });
  } catch (error) {
    // proxy.$modal.msgError(error);
    console.error(error);
  }
};

// 打开侧边栏
const drawerOpen = () => {
  getModel(carList.value[car.value].id);
  drawer.value = true;
  brandIndex.value = car.value;
  modelIndex.value = model.value;
};

// 选择品牌
const selectBrand = (index) => {
  brandIndex.value = index;
  getModel(carList.value[index].id);
};

// 选择车型
const selectModel = (index) => {
  modelIndex.value = index;
};

// 重置
const resetClick = () => {
  drawer.value = false;
};

// 确认
const confirmClick = () => {
  if (modelList.value.length !== 0 && modelIndex.value === -1) {
    proxy.$modal.msgError(
      language.value === "English" ? "Please select a car model" : "请选择车型"
    );
    return;
  }
  drawer.value = false;
  carName.value =
    language.value === "English"
      ? carList.value[brandIndex.value]?.englishName
      : carList.value[brandIndex.value]?.name;
  modelName.value =
    language.value === "English"
      ? modelList.value[modelIndex.value]?.englishName
      : modelList.value[modelIndex.value]?.name;

  car.value = brandIndex.value;
  model.value = modelIndex.value;
  conversation_id.value = null;
  rateValue.value = 0;
};

onMounted(() => {
  const userAgent = navigator.userAgent || window.opera;
  if (/Mobi|Android/i.test(userAgent)) {
    deviceType.value = 1;
  } else {
    deviceType.value = 2;
  }
  user.value = Date.parse(new Date());
  let lang = localStorage.getItem("lang");
  if (lang === "en") {
    language.value = "English";
  } else {
    lang = "zh";
    language.value = "中文";
    localStorage.setItem("lang", "zh");
  }
  configStore.setLanguage(lang);
  getAgent();
  getStartWords();
  getBrand(1);
});

onUnmounted(() => {
  if (timer.value) {
    clearTimeout(timer.value);
  }
});
</script>
<style  lang="scss" scoped>
@media screen and (max-width: 500px) {
  .checkoutProduct {
    max-width: 140px !important;
  }
}
@media screen and (max-width: 768px) {
  .title {
    height: 142px !important;
    .title_box {
      padding: 15px 15px 0 !important;
      .title_info {
        height: 70px !important;
        font-size: 12px !important;
      }
      .agent_image {
        width: 50px !important;
        height: 62px !important;
      }
    }
  }
  .checkoutProduct {
    height: 26px;
    font-size: 12px !important;
  }
  .icon-right {
    top: 6px !important;
    font-size: 14px !important;
  }
  .content {
    height: calc(100vh - 85px) !important;
  }

  .agent-answer-text {
    max-width: 290px !important;
    font-size: 14px !important;
  }
  .answer_quest_button {
    font-size: 10px;
    width: 130px !important;
    margin: 0 0 8px !important;
  }
  .answer_quest_while {
    width: 130px !important;
  }
  .user-quest-text {
    max-width: 290px !important;
    font-size: 14px !important;
  }
  .subInput {
    margin-right: 14px !important;
  }
  .model-title {
    margin: 30px 0 10px !important;
  }
  .brand-item-box {
    width: 46%;
  }
  .brand-button {
    width: 100% !important;
    // margin: 0 16px 12px 0 !important;
  }
  .brand-none {
    width: 100% !important;
    // margin: 0 16px 12px 0 !important;
  }
  .answer_image {
    width: 120px !important;
    height: 120px !important;
  }
  .problem-button-item {
    font-size: 12px !important;
    height: 30px !important;
  }
  .currentTime {
    margin: 23px 0 17px !important;
  }
  .answer-loading {
    font-size: 14px !important;
  }
  .k-loader:before,
  .k-loader:after {
    top: 5px !important;
  }
}

@keyframes ai-answer {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.loading-box {
  display: flex;
}

.k-circle {
  margin-left: 6px;
}

.k-loader {
  position: relative;
  height: 20px;
  width: 24px;
}

.k-loader:before,
.k-loader:after {
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  top: 7px;
  left: 0;
  width: 10px;
}

.k-loader:before {
  border-radius: 50% 50% 50% 0;
  background: #000;
  animation: k-loadingK 2s ease-in-out infinite;
}

.k-loader:after {
  border-radius: 100%;
  animation: k-loadingL 2s ease-in-out infinite;
  background: rgba(255, 255, 255, 0.4);
  border: 0.5px solid #000;
}

@keyframes k-loadingK {
  0% {
    left: 0;
    transform: scale(1.1);
  }

  50% {
    left: 50%;
    transform: scale(1);
  }

  100% {
    left: 0;
    transform: scale(1.1);
  }
}

@keyframes k-loadingL {
  0% {
    left: 50%;
    transform: scale(1.1);
  }

  50% {
    left: 0;
    transform: scale(1);
  }

  100% {
    left: 50%;
    transform: scale(1.1);
  }
}

.rate-drawer {
  :deep(.el-drawer__body) {
    padding-top: 0 !important;
  }
  :deep(.el-drawer) {
    background: linear-gradient(180deg, #b4dfff 0%, #ffffff 33%);
    border-radius: 16px 16px 0px 0px;
    height: 234px !important;
  }
}
.rate-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  .el-rate {
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    :deep(.el-rate__icon) {
      font-size: 30px;
    }
  }
}

.rate-button {
  background-image: url("@/assets/icon/rate_stars.png");
  background-size: cover;
  display: inline-block;
  width: 1em;
  height: 1em;
}

.answer-loading {
  background: #ffffff;
  border-radius: 2px 16px 16px 16px;
  font-size: 16px;
  width: 46px;
  height: 44px;
  padding: 14px 12px;
  margin: 12px 0 0 47px;
}

.link-text {
  color: rgba(32, 37, 50, 1) !important;
  padding: 0;
  height: 100%;
  white-space: normal;
  text-align: left;
  line-height: 20px;
  &:hover {
    background-color: #fff !important;
  }
  text-decoration-color: rgba(32, 37, 50, 1);
  text-decoration: underline;
  text-underline-offset: 5px;
  .el-icon--left {
    flex: 1 0 auto;
    margin-right: 12px;
  }
}

.answer_video {
  width: 100%;
}

.home-box {
  // display: flex;
  // flex-direction: column;
  height: 100vh;
  width: 100vw;
}
.title {
  height: 188px;
  background: linear-gradient(180deg, #b4dfff 0%, #ffffff 63%);
  .title_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100vw;
    padding: 23px 18px 0;
    background: linear-gradient(180deg, #b4dfff 0%, #ffffff 63%);
    .title_info {
      display: flex;
      align-items: flex-end;
      height: 108px;
      font-size: 16px;
      color: #202532;
    }
    .agent_image {
      width: 85px;
      height: 105px;
    }
    .title_text {
      flex: 1;
      padding-left: 12px;
      padding-bottom: 8px;
    }
    .name {
      margin-bottom: 6px;
    }
    .checkout-button-box {
      position: relative;
    }
    .icon-right {
      position: absolute;
      top: 8px;
      right: 14px;
    }
    .checkoutProduct {
      font-size: 14px;
      margin-bottom: 8px;
      padding-right: 36px;
      :deep(span) {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.content {
  height: calc(100vh - 131px);
  position: relative;
  top: -57px;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  background: #f5f8ff;
  border-radius: 16px 16px 0px 0px;
  overflow: auto;
  .currentTime {
    font-size: 12px;
    margin: 33px 0 20px;
    text-align: center;
    color: #a4a5a9;
  }
  .agent-answer {
    display: flex;
    padding-top: 12px;
    animation: ai-answer 0.5s;
    .agent-image-box {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #fff;
      margin: 5px 7px 0 0;
      .agent_image {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .agent-answer-text {
      position: relative;
      background: #ffffff;
      border-radius: 2px 16px 16px 16px;
      min-height: 52px;
      max-width: 50%;
      font-size: 16px;
      padding: 14px 12px;
    }
    .answer_image {
      width: 180px;
      height: 180px;
    }
    .answer_quest {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 24px;
    }
    .answer_quest_button {
      justify-content: flex-start;
      width: 170px;
      height: 100%;
      line-height: 18px;
      color: #202532;
      white-space: normal;
      font-size: 13px;
      text-align: left;
    }
    .answer_quest_while {
      width: 170px;
    }
  }
  .user-quest {
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
    .user-quest-text {
      min-height: 42px;
      max-width: 562px;
      font-size: 16px;
      color: #ffffff;
      padding: 11px 22px;
      background: #0069d8;
      border-radius: 16px 16px 2px 16px;
    }
  }

  .problem-button {
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 280px;
    .problem-button-item {
      color: #a4a5a9;
    }
  }

  .local-icon {
    background-image: url("@/assets/icon/icon_like.png");
    background-size: cover;
    display: inline-block;
    width: 1em;
    height: 1em;
  }
  .link-icon {
    background-image: url("@/assets/icon/xbz-icon-link.svg");
    background-size: cover;
    display: inline-block;
    width: 1em;
    height: 1em;
  }
  .local-icon-reject {
    transform: rotateX(180deg);
  }
  .problem_question {
    margin-bottom: 40px;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  .footer-tips {
    display: flex;
    padding: 0 18px 7px;
  }
  .footer-rate {
    margin-right: 12px;
  }
  .footer-tips-item {
    display: flex;
  }
  .tips-button {
    color: #202532;
    background: #eff2f7;
  }
  .footer-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 88px;
    background-color: #fff;
    padding: 10px 16px 30px;
    .el-button--primary {
      background: #0069d8;
      border-color: #0069d8;
    }
    .is-loading::before {
      background: none;
    }
    .subInput {
      height: 100%;
      margin-right: 20px;
      :deep(.el-input__wrapper) {
        border-radius: 8px 8px 8px 8px;
        background: #eff2f7;
      }
    }
  }
}

.brand-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .brand-button {
    width: 128px;
    height: 100%;
    line-height: 18px;
    white-space: normal;
    text-align: left;
    // margin: 0 18px 12px 0;
    background-color: #fff !important;
  }
  .brand-none {
    width: 128px;
  }
  .brand-defult {
    border: 1px solid #f2f2f2;
  }
  .brand-color {
    border: 1px solid #409eff;
  }
}

.model-title {
  color: #202532;
  margin: 50px 0 10px;
}

.drawer-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0 30px;
  .foot-btn {
    width: 120px;
    height: 40px;
    margin-left: 0;
  }
}
</style>
