export default {
  // common
  common: {
    language: '简体中文',
    changeProduct: '为了提供更优质的服务 请选择需咨询产品的信息',
    car_placeholder: '汽车品牌',
    changeCarType: '请选择产品',
    model_placeholder: '具体车型',
    changeModelType: '请选择车型',
    join: '立即咨询',
    sub_placeholder: '请输入你的问题或需求...',
    name: '新小博',
    identify: '新博智转向工程师',
    carName: '东风',
    carType: '风光',
    brand: '品牌',
    model: '车型',
    confirm: '确定',
    reset: '重置',
    quest: '请问您的问题解决了吗?',
    Resolved: '已解决',
    Unresolved: '未解决',
    evaluate: '评价',
    evaluateVal: '感谢您为本次会话打分',
  }
}