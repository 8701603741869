import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
import baseUrl from "@/utils/config.js";

// 是否显示重新登录
export let isRelogin = { show: false };

// 创建axios
const service = axios.create({
  baseURL: baseUrl,
  timeout: 600000,
});

// post请求头
service.defaults.headers.post["Content-Type"] = "application/json";

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 为请求头对象添加token验证
    if (config.url.indexOf("http") !== -1) {
      config.baseURL = "";
    }
    config.headers.Authorization = "9f634fb8-cb50-bdb4-879c-d86ce47679c7";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    console.log("服务器响应成功");
    if (res.data.code === 500) {
      ElMessage({ message: res.data.msg, type: "error" });
      return Promise.reject(new Error(res.data.msg));
    } else if (res.data.code === 601) {
      ElMessage({ message: res.data.msg, type: "warning" });
      return Promise.reject(new Error(res.data.msg));
    } else if (res.data.code !== 200) {
      ElMessage({ message: res.data.msg, type: "error" });
      return Promise.reject("error");
    } else {
      return Promise.resolve(res.data);
    }
  },
  (error) => {
    switch (error.response.status) {
      case 500:
        console.log("服务器响应错误");
        break;
    }

    ElMessage({ message: error.message, type: "error", duration: 5 * 1000 });
    return Promise.reject(error);
  }
);

export default service;
