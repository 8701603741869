<template>
  <el-config-provider :locale="lang[language]">
    <router-view />
  </el-config-provider>
</template>

<script setup>
import { computed } from "vue";
import { useConfigStore } from "./stores/config";
import zh from "element-plus/es/locale/lang/zh-cn";
import en from "element-plus/es/locale/lang/en";

const configStore = useConfigStore();
const language = computed(() => configStore.language);
const lang = { zh, en };
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  // font-weight: 600;
}
body {
  margin: 0;
  .el-select-dropdown__wrap {
    max-height: 400px;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: hidden;
  width: 100vw;
}
</style>
