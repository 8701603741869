import { createI18n } from 'vue-i18n'
import en from './en'
import zh from './zh'

const messages = { en, zh }

const i18n = createI18n({
  globalInjection: true, // 如果设置true, $t() 函数将注册到全局
  legacy: false, // 如果想在composition api中使用需要设置为false
  locale: localStorage.getItem('language') || 'zh', // 当前选择的语言
  messages, // set locale messages
})


export default i18n